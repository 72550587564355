import PropTypes from 'prop-types';
import { SnackbarContent, useSnackbar } from 'notistack';

import { forwardRef, useCallback, useState } from 'react';

// mui
import { Alert, Box, Stack, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

// project imports
import SendBookingDetailsDialog from 'ui-component/dialogs/SendBookingDetailsDialog';
import ViewBookingDialog from 'views/shared/manage-bookings/ViewBookingDialog';
import ViewMobileBookingDialog from 'views/shared/manage-bookings/ViewMobileBookingDialog';
import { useGetEntityName } from 'utils/entities';

const BookingActionSnackbar = forwardRef((props, ref) => {
  const respondentEntityName = useGetEntityName('respondent').toLowerCase();
  const bookingEntityName = useGetEntityName('Booking');

  const { id, variantProps } = props;
  const { bookingId, isMobileBooking, messageType, hideSendButton } = variantProps;
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [openSendBookConfirmDialog, setOpenSendBookConfirmDialog] = useState(false);
  const [openViewBookingDialog, setOpenViewBookingDialog] = useState(false);

  const handleClose = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleSend = () => {
    setOpenSendBookConfirmDialog(true);
  };

  const snackbarDescription = messageType.getSnackbarMessage(bookingEntityName, bookingId);

  return (
    <SnackbarContent ref={ref}>
      <Alert
        severity="success"
        variant="outlined"
        sx={{ bgcolor: 'background.paper', width: '100%', border: '0.5px solid' }}
        color="primary"
        action={<CloseIcon onClick={handleClose} sx={{ mr: 1 }} />}
      >
        <Box sx={{ width: 350 }}>
          <Typography variant="h5">
            {snackbarDescription}
            {hideSendButton ? ` and details sent to ${respondentEntityName} based on the selected contact method.` : ''}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ pt: 2 }}>
            <Button onClick={() => setOpenViewBookingDialog(true)} variant="contained" color="primary" size="small">
              View Details
            </Button>
            {!hideSendButton && (
              <Button startIcon={<SendIcon />} onClick={handleSend} variant="outlined" color="primary" size="small">
                Send
              </Button>
            )}
          </Stack>
        </Box>
      </Alert>
      <SendBookingDetailsDialog
        open={openSendBookConfirmDialog}
        setOpen={setOpenSendBookConfirmDialog}
        dialogProps={variantProps}
        handleCloseSnackbar={closeSnackbar}
        snackbarId={id}
        displaySnackbarAtParent={enqueueSnackbar}
      />

      {bookingId &&
        (isMobileBooking ? (
          <ViewMobileBookingDialog
            open={openViewBookingDialog}
            setOpen={setOpenViewBookingDialog}
            bookingId={bookingId}
            enableStatusUpdate={false}
            isReadOnly
          />
        ) : (
          <ViewBookingDialog
            open={openViewBookingDialog}
            setOpen={setOpenViewBookingDialog}
            bookingId={bookingId}
            enableStatusUpdate={false}
            isReadOnly
          />
        ))}
    </SnackbarContent>
  );
});

BookingActionSnackbar.propTypes = {
  id: PropTypes.string,
  variantProps: PropTypes.object
};

export default BookingActionSnackbar;
