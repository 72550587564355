/* eslint-disable no-restricted-syntax */
import userTypes from 'constants/userTypes';

const getUserPermissions = (user = {}) => {
  const accessRoles = user.AccessRoles || [];
  const permissions = new Set();

  for (const accessRole of accessRoles) {
    for (const permission of JSON.parse(accessRole.permissions)) {
      permissions.add(permission);
    }
  }
  return Array.from(permissions);
};

const userHasPermission = ({ userData = {}, validPermissionsAdmin = [], validPermissionsManager = [] }) => {
  let hasPermission = true;
  let validPermissions = [];

  if (userData.type === userTypes.Admin) {
    validPermissions = validPermissionsAdmin;
  } else if (userData.type === userTypes.Manager || userData.type === userTypes.ExternalUser) {
    validPermissions = validPermissionsManager;
  }

  if (validPermissions.length > 0) {
    hasPermission = false;
    // get user permissions
    const userPermissions = getUserPermissions(userData);

    for (const permission of validPermissions) {
      if (userPermissions.includes(permission)) {
        hasPermission = true;
        break;
      }
    }
  }

  return hasPermission;
};

// eslint-disable-next-line import/prefer-default-export
export { userHasPermission };
