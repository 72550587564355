import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { TimePicker, DatePicker, StaticDatePicker } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const CustomTimePicker = ({
  value,
  defaultValue,
  onChange,
  label = null,
  views = ['hours', 'minutes'],
  timeSteps = { hours: 1, minutes: 1 },
  format = 'hh:mm a',
  disableFuture = false,
  ...others
}) => (
  <TimePicker
    value={value ? dayjs(value) : null}
    defaultValue={defaultValue ? dayjs(defaultValue) : null}
    onChange={onChange}
    label={label}
    views={views}
    timeSteps={timeSteps}
    format={format}
    disableFuture={disableFuture}
    {...others}
  />
);

CustomTimePicker.propTypes = {
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  views: PropTypes.array,
  timeSteps: PropTypes.object,
  format: PropTypes.string,
  disableFuture: PropTypes.bool
};

export const CustomDatePicker = ({
  value,
  defaultValue,
  minDate,
  maxDate,
  onChange,
  label = null,
  format = 'DD/MM/YYYY',
  disableFuture = false,
  ...others
}) => (
  <DatePicker
    value={value ? dayjs(value) : null}
    defaultValue={defaultValue ? dayjs(defaultValue) : null}
    minDate={minDate ? dayjs(minDate) : null}
    maxDate={maxDate ? dayjs(maxDate) : null}
    onChange={onChange}
    label={label}
    format={format}
    disableFuture={disableFuture}
    {...others}
  />
);

CustomDatePicker.propTypes = {
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  disableFuture: PropTypes.bool
};

const CustomStyledDatePicker = styled(StaticDatePicker)({
  '& .MuiPickersDay-root': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500
  },
  '& .Mui-selected': {
    color: '#fff !important'
  }
});

export const CustomStaticDatePicker = ({
  value,
  defaultValue,
  minDate,
  maxDate,
  onChange,
  label = null,
  format = 'DD/MM/YYYY',
  ...others
}) => (
  <CustomStyledDatePicker
    value={value ? dayjs(value) : null}
    defaultValue={defaultValue ? dayjs(defaultValue) : null}
    minDate={minDate ? dayjs(minDate) : null}
    maxDate={maxDate ? dayjs(maxDate) : null}
    onChange={onChange}
    label={label}
    format={format}
    // slotProps={{ actionBar: { actions: [] } }}
    {...others}
  />
);

CustomStaticDatePicker.propTypes = {
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  disableFuture: PropTypes.bool
};
