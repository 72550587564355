// assets
import {
  IconShieldHalfFilled,
  IconFileDescription,
  IconUsers,
  IconPencil,
  IconReportAnalytics,
  IconPhoto,
  IconUserCircle,
  IconLayoutDashboard,
  IconClipboardText,
  IconAddressBook,
  IconMapPins,
  IconLayersSubtract,
  IconCalendarEvent,
  IconMailOpened,
  IconBook2,
  IconUserShare,
  IconAffiliate
} from '@tabler/icons-react';

// constant

// ==============================|| ADMIN MENU ITEMS ||============================== //

const surveyPages = {
  id: 'surveyPages',
  title: 'Surveys',
  type: 'group',
  children: [
    {
      id: 'manage-survey-backgrounds',
      title: 'Survey Backgrounds',
      type: 'item',
      url: '/admin-manage-survey-backgrounds',
      icon: IconPhoto,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'survey-typess',
      entityId: 'surveyTypes',
      title: 'Survey Types',
      type: 'item',
      url: '/manage-survey-types',
      icon: IconPencil,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'surveys',
      entityId: 'surveys',
      title: 'View Surveys',
      type: 'item',
      url: '/view-surveys',
      icon: IconClipboardText,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'manage-survey-reports',
      title: 'Reports',
      type: 'item',
      url: '/manage-survey-reports',
      icon: IconReportAnalytics,
      breadcrumbs: false,
      minimumPermissions: []
    }
  ]
};

const userPages = {
  id: 'userPages',
  title: 'Users',
  type: 'group',
  children: [
    {
      id: 'manage-admins',
      entityId: 'admins',
      title: 'Administrators',
      type: 'item',
      url: '/manage-admins',
      icon: IconUserCircle,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'manage-managers',
      entityId: 'managers',
      title: 'Managers',
      type: 'item',
      url: '/manage-managers',
      icon: IconFileDescription,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'manage-ext-users',
      entityId: 'externalUsers',
      title: 'External Users',
      type: 'item',
      url: '/manage-ext-users',
      icon: IconUserShare,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'respondents',
      entityId: 'respondents',
      title: 'Respondents',
      type: 'item',
      url: '/respondents',
      icon: IconUserCircle,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'manage-access-roles',
      title: 'Access Roles',
      type: 'item',
      url: '/manage-access-roles',
      icon: IconShieldHalfFilled,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'manage-recipients',
      title: 'Recipients',
      type: 'item',
      url: '/manage-recipients',
      icon: IconAddressBook,
      breadcrumbs: false,
      minimumPermissions: []
    }
  ]
};

const dashboardPages = {
  id: 'dashboardPages',
  title: '',
  type: 'group',
  children: [
    {
      id: 'bookingStats',
      title: 'Booking Dashboard',
      type: 'item',
      url: '/dashboard-booking-stats',
      icon: IconLayoutDashboard,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'surveyStats',
      title: 'Survey Dashboard',
      type: 'item',
      url: '/dashboard-survey-stats',
      icon: IconLayoutDashboard,
      breadcrumbs: false,
      minimumPermissions: []
    }
  ]
};

const bookingPages = {
  id: 'bookingPages',
  title: 'Bookings and Scheduling',
  type: 'group',
  children: [
    {
      id: 'services',
      title: 'Services',
      type: 'item',
      url: '/manage-services',
      icon: IconUsers,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'locations',
      title: 'Locations',
      type: 'item',
      url: '/manage-locations',
      icon: IconMapPins,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'booking-pages',
      title: 'Booking Pages',
      type: 'item',
      url: '/manage-booking-pages',
      icon: IconLayersSubtract,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'bookings',
      title: 'Bookings',
      type: 'item',
      url: '/manage-bookings',
      icon: IconBook2,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'booking-calendar',
      title: 'Calendar',
      type: 'item',
      url: '/manage-booking-calendar',
      icon: IconCalendarEvent,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'booking-confirmation-text',
      title: 'Confirmation Text',
      type: 'item',
      url: '/manage-booking-confirmation-text',
      icon: IconMailOpened,
      breadcrumbs: false,
      minimumPermissions: []
    }
  ]
};

const companyPages = {
  id: 'companies',
  title: 'Companies',
  type: 'group',
  children: [
    {
      id: 'companies',
      title: 'Companies',
      type: 'item',
      url: '/manage-companies',
      icon: IconAffiliate,
      breadcrumbs: false,
      minimumPermissions: []
    }
  ]
};

const adminPages = [dashboardPages, userPages, companyPages, bookingPages, surveyPages];

export default adminPages;
