const calendarModes = {
  ByLocation: 'ByLocation',
  ByLocationAndManager: 'ByLocationAndManager',
  ByManager: 'ByManager'
};

const calendarEventTypes = {
  booking: 'booking',
  outlookCalendar: 'outlookCalendar',
  googleCalendar: 'googleCalendar',
  bufferTimeConstraint: 'bufferTimeConstraint',
  blockedSlot: 'blockedSlot',
  managerBlockedSlot: 'managerBlockedSlot'
};

const googleCalendar = {
  // maximum number of users to be considered when fetching calendar events
  maxUsers: 10,
  // maximum number of days to be considered when fetching calendar events
  // i.e. difference in days from start to end date
  maxDays: 14
};

export { calendarModes, calendarEventTypes, googleCalendar };
