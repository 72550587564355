const companyLabelBuilder = (option) => {
  const labelParts = [];
  if (option.name) {
    labelParts.push(`${option.name}`);
  }

  if (option.id || (option.source && option.sourceId)) {
    labelParts.push(' [');
  }

  if (option.id) {
    labelParts.push(`AtlasOne ${option.id}`);
  }

  if (option.id && option.source && option.sourceId) {
    labelParts.push(', ');
  }

  if (option.source && option.sourceId) {
    labelParts.push(`${option.source} ${option.sourceId}`);
  }

  if (option.id || (option.source && option.sourceId)) {
    labelParts.push(']');
  }

  return labelParts.join('');
};

export default companyLabelBuilder;
