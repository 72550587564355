import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useSnackbar, MaterialDesignContent } from 'notistack';

import BookingActionSnackbar from 'ui-component/snackbars/BookingActionSnackbar';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const notistackClasses = {
  containerRoot: 'z-alert',
  containerAnchorOriginTopRight: 'z-anchor-origin-top-right',
  containerAnchorOriginTopCenter: 'z-anchor-origin-top-center',
  containerAnchorOriginTopLeft: 'z-anchor-origin-top-left'
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-info': {
    backgroundColor: '#00C4C4'
  }
}));

const notistackCustomSnackbars = {
  BOOKING_ACTION: BookingActionSnackbar,
  info: StyledMaterialDesignContent
};

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon style={{ color: 'white' }} />
    </IconButton>
  );
};

SnackbarCloseButton.propTypes = {
  snackbarKey: PropTypes.any
};

export { notistackClasses, notistackCustomSnackbars, SnackbarCloseButton };
