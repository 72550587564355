import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// notistack closeSnackbar
import { closeSnackbar } from 'notistack';

// dayjs
import dayjs from 'dayjs';

// project imports
import { handleLogout } from 'utils/logout';
import navigateStates from 'constants/navigateStates';

// events that reset timer
const events = ['load', 'mousedown', 'click', 'scroll', 'keydown', 'touchstart', 'touchend'];

// set the time value for inactivity (in milliseconds)
const inactivityTimeout = 1800000; // 30 minutes; 30m * 60s/m * 1000ms/s = 1800000ms

// message to show on login page after logout due to inactivity
const inactivityMessage = 'You have been automatically logged out for security purposes, please log back in.';

// a list of route paths that should be ignored when redirecting after logout
const logoutRedirectExclusions = [
  // auth
  '/login',
  '/login-manager',
  '/redirect',
  // public booking
  '/booking-page',
  // public survey
  '/surveys',
  // api routes
  '/api'
];

const AppLogout = ({ children }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  // logs out user
  const logoutAction = useCallback(async () => {
    const skipLogoutRedirect = logoutRedirectExclusions.find((ex) => location.pathname.startsWith(ex));
    await handleLogout();
    if (!skipLogoutRedirect) {
      navigate('/login');
    }
  }, [location.pathname, navigate]);

  const setLastRecordedActivity = () => {
    // record timestamp of last activity
    localStorage.setItem('lastRecordedActivity', new Date().toISOString());
  };

  // closes all notistackbars when route changes
  useEffect(() => {
    if (location.state !== navigateStates.FirstRedirect) {
      closeSnackbar();
    }
  }, [location]);

  useEffect(() => {
    let checkInactivity;

    const handleAutoLogout = () => {
      const lastRecordedActivity = localStorage.getItem('lastRecordedActivity');
      if (lastRecordedActivity && dayjs().diff(dayjs(lastRecordedActivity), 'millisecond') >= inactivityTimeout) {
        localStorage.removeItem('lastRecordedActivity');
        localStorage.setItem('inactivityMessage', inactivityMessage);
        logoutAction();
      }
    };

    handleAutoLogout();

    if (isLoggedIn) {
      // each time any of the events is triggered, i.e on mouse click, scroll etc, a timestamp of the event is saved in local storage
      Object.values(events).forEach((item) => {
        window.addEventListener(item, setLastRecordedActivity);
      });

      // every 10 seconds, the last activity timestamp is checked against the allowed inactivity timeout
      // if it exceeds the timeout, the user is automatically logged out
      checkInactivity = setInterval(handleAutoLogout, 10000);
    }

    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, setLastRecordedActivity);
      });

      if (checkInactivity) {
        clearInterval(checkInactivity);
      }
    };
  }, [isLoggedIn, logoutAction]);

  // This triggers the user to logout from the app across all tabs within a browser whenever 'isLoggedOut' value is changed to FALSE
  // Currently, there are two workflows that triggers this:
  // -- User is logged in and clicks logout at the Profile Section in the app
  // -- User is at the login page and selects an authentication method (Microsoft or Google), manifesting intention to login again
  useEffect(() => {
    const loginEventListener = (e) => {
      if (e.key === 'isLoggedIn' && e.newValue === 'false') {
        logoutAction();
      }
    };

    window.addEventListener('storage', loginEventListener);

    return () => {
      window.removeEventListener('storage', loginEventListener);
    };
  }, [logoutAction]);

  return children;
};

export default AppLogout;
